import { useMemo, useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, VSeparator, Opaque, HFlex, VFlex, BigLoader } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { sendCollectTxns, sendDraw1Txns } from '../algo.js';
import { account, history, assets, localAssets, localAppState, globalAppState } from '../state/';
import Play from '../components/Play.jsx';
import NFTs from '../components/NFTs.jsx';
import AllNFTTable from '../components/AllNFTTable.jsx';
import AllNFTs from '../components/AllNFTs.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { YourHistory, GlobalHistory, Leaderboard } from '../components/History.jsx';
import Audit from './Audit';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cp-data-tabpanel-${index}`}
      aria-labelledby={`cp-data-tab-${index}`}
      {...other}
    >
      {value === index && (<>
          {children}
      </>)}
    </div>
  );
}

function a11yProps(_id) {
  const { id } = tabs.find(({id}) => id === _id);
  return {
    id,
    'aria-controls': `cp-tabpanel-${id}`,
  };
}

const tabs = [
  { id: "history", title: "Your History", route: "/data/#history", },
  { id: "odds", title: "team odds", route: "/data/#odds", },
  { id: "nfts", title: "NFTs", route: "/data/#nfts", },
  { id: "global-history", title: "Global History", route: "/data/#global-history", },
  { id: "leaderboard", title: "Leaderboard", route: "/data/#leaderboard", },
  { id: "audit", title: "Audit", route: "/data/#audit", },
  // { id: "analytics", title: "Analytics", route: "/data/#analytics", hide: true, },
]

function Data({ account }) {
  const location = useLocation();
  const navigate = useNavigate();

  const value = useMemo(() => {
    const def = (account.address ? 0 : 1);
    const { hash } = location;
    if (hash && hash.startsWith('#')) {
      const idx = tabs.findIndex(tab => tab.route.endsWith(hash));
      return idx ?? def
    }
    return def;
  }, [location.hash, account.address]);

  const handleChange = (event, newValue) => {
    const { route } = tabs[newValue];
    navigate(route);
  };

  return <>
    <VSeparator id="data" />

    <Opaque>
      <Typography align="center" id="#table" variant="h2">DATA & ANALYTICS</Typography>
      <HFlex>
        <Tabs
          value={value} 
          TabIndicatorProps={{ sx: { display: 'none' } }}
          onChange={handleChange}
          aria-label="Data navigation" sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
          },
          }}>
          {tabs.map(({ id, title, }) =>
            <Tab key={`tabhead-${id}`} label={title} {...a11yProps(id)} />
          )}
        </Tabs>
      </HFlex>
    </Opaque>

    <VSeparator />

    <TabPanel value={value} index={0}>
      <VFlex>
        <YourHistory assets={assets} address={account.address} history={history} />
      </VFlex>
    </TabPanel>

    <TabPanel value={value} index={1}>
      <Opaque>
        <Typography align="center" sx={{mb: 2}} variant="h2">ODDS DATA</Typography>
        <AllNFTTable history={history} assets={assets} localAssets={localAssets} />
      </Opaque>
    </TabPanel>

    <TabPanel value={value} index={2}>
      <Opaque op={0}>
        <Typography align="center" className="glow" sx={{mb: 2}} variant="h2">CUPSTAKE NFTs</Typography>
        <HFlex>
          <AllNFTs assets={assets} localAssets={localAssets} />
        </HFlex>
      </Opaque>
    </TabPanel>

    { /* <TabPanel value={value} index={3}>
      <Opaque>
        <Typography align="center" className="glow" sx={{mb: 2}} variant="h2">LEADERBOARD</Typography>
        <HFlex>
        </HFlex>
      </Opaque>
    </TabPanel> */ }

    <TabPanel value={value} index={3}>
      <VFlex sx={{ width: 1 }}>
        <GlobalHistory history={history} />
      </VFlex>
    </TabPanel>

    <TabPanel value={value} index={4}>
      <VFlex sx={{ width: 1 }}>
        <Leaderboard assets={assets} history={history} />
      </VFlex>
    </TabPanel>

    <TabPanel value={value} index={5}>
      <Audit />
    </TabPanel>

    <VSeparator />
  </>
}

/* Your History */
/* Leaderboard */
/* All Actions */
/* Odds */
/* Analytics */

export default observer(Data);

